import React from "react"
import Layout from "../../layouts/default"

import Box from "../../components/box"
import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"

const NeuigkeitenHoerl = () => {
  return (
    <Layout backdrop="hoerl">
      <Seo
        title="HÖldeRLin in TÜBINGEN"
        description="Eine Skulptureninstallation von Ottmar Hörl in Zusammenarbeit mit der Galerie Art 28 und der Universitätsstadt Tübingen"
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "HÖldeRLin in TÜBINGEN",
              link: "/neuigkeiten/hoelderlin-in-tuebingen",
            },
          ]}
        />
        <PageTitle>HÖldeRLin in TÜBINGEN</PageTitle>
        <Heading level={5}>
          Eine Skulptureninstallation von Ottmar Hörl in Zusammenarbeit mit der
          Galerie Art 28 und der Universitätsstadt Tübingen
        </Heading>
        <Paragraph dropcap={true}>
          Hölderlin wimmelt. 250 je 66 cm große Hölderlin-Figuren in gold,
          schwarz, nacht-blau und opalgrün besetzen derzeit die Tübinger
          Neckarfront und Treppen der Stiftskirche am Holzmarkt. Geschaffen hat
          sie der renommierte deutsche Konzeptkünstler Ottmar Hörl, emeritierter
          Professor und früherer Präsident der Akademie der Bildenden Künste in
          Nürnberg.
        </Paragraph>
        <Paragraph>
          Hörls Skulpturen-Installation ist das Gegenteil eines klassischen
          Denkmals. Ihm geht es vielmehr um eine Auseinandersetzung auf
          Augenhöhe – ohne Aura und Berührungsängste. Die Serialität der Figuren
          macht seine Kunst demokratisch. Jede und jeder kann an der
          künstlerischen Idee teilnehmen. Als dreidimensionale Skulptur
          entwickeln die Figuren eine direkte Nähe und Präsenz. Aufgrund der
          relativen Neutralität werden sie zu einer Projektionsfläche, in die
          Menschen ihre eigenen Gedanken hineinprojizieren können ohne, dass
          »Hörl sozusagen dazwischen steht«, betont Ottmar Hörl.
        </Paragraph>
        <Box
          sx={{
            bg: "muted",
            p: 6,
            borderLeftWidth: 4,
            borderColor: "primary",
            borderStyle: "solid",
          }}
        >
          <Paragraph>
            Die Installation ist bis zum 25. Oktober 2020 in Tübingen zu sehen.
          </Paragraph>
        </Box>
        <Paragraph>
          Die Hölderlin-Installation ist ein Projekt der{" "}
          <TextLink href="https://www.art28.com">Art 28 GmbH & Co. KG</TextLink>
        </Paragraph>
        <Paragraph>
          Im Projektbüro in der Volksbank-Filiale am Marktplatz (Am Markt 14,
          Tübingen) kann man sich bis zum 25. Oktober 2020 eine der limitierten
          Skulpturen zum Subskriptionspreis sichern.
        </Paragraph>
      </Stack>
    </Layout>
  )
}

export default NeuigkeitenHoerl
